// Set mailto for all email links on the page
document.querySelectorAll('a.email').forEach(element => {
  element.href = ''
  element.addEventListener('click', function (event) {
    event.preventDefault()
    const username = this.dataset.username
    const domain = this.dataset.domain
    window.location.href = `mailto:${username}@${domain}`
  })
})
