// Initialize MapKit
global.mapkit.init({
  authorizationCallback: (done) => {
    const token = document.querySelector('body').dataset.mapkitToken
    done(token)
  }
})

// Create maps
document.querySelectorAll('.mapkit').forEach(element => {
  const title = element.dataset.title
  const subtitle = element.dataset.subtitle
  const lat = parseFloat(element.dataset.lat)
  const lon = parseFloat(element.dataset.lon)
  const span = parseFloat(element.dataset.span)

  // Region
  const coordinate = new global.mapkit.Coordinate(lat, lon)
  const region = new global.mapkit.CoordinateRegion(
    coordinate,
    new global.mapkit.CoordinateSpan(span, span)
  )

  // Annotation
  const calloutDelegate = {
    calloutRightAccessoryForAnnotation: () => {
      const accessoryViewRight = document.createElement('a')
      accessoryViewRight.href = `https://maps.apple.com/?q=${title}%20${subtitle}&ll=${lat},${lon}`
      accessoryViewRight.target = '_blank'
      accessoryViewRight.appendChild(document.createTextNode('Itinéraire'))
      return accessoryViewRight
    }
  }

  const marker = new global.mapkit.MarkerAnnotation(coordinate, {
    title: title,
    subtitle: subtitle,
    callout: calloutDelegate
  })

  // Map
  // eslint-disable-next-line no-new
  new global.mapkit.Map(element, {
    region: region,
    annotations: [marker],
    isRotationEnabled: false,
    showsScale: global.mapkit.FeatureVisibility.Adaptive
  })
})
